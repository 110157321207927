import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom';

// Editor styles
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/icons/default';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

export default () => {
  tinymce.init({
    promotion: false,
    skin: false,
    content_css: false,
    browser_spellcheck: true,
    contextmenu: 'link useBrowserSpellcheck',
    setup(editor) {
      editor.ui.registry.addMenuItem('useBrowserSpellcheck', {
        text: 'Use `Ctrl+Click` to access spellchecker',
        icon: 'spell-check',
        onAction() {
          editor.notificationManager.open({
            text:
    'To access the spellchecker, hold the Control (Ctrl) key and click on the misspelled word.',
            type: 'info',
            timeout: 5000,
            closeButton: true,
          });
        },
      });
      editor.ui.registry.addContextMenu('useBrowserSpellcheck', {
        update() {
          return editor.selection.isCollapsed() ? ['useBrowserSpellcheck'] : [];
        },
      });
    },
    selector: 'textarea.tinymce',
    height: 500,
    plugins: ['fullscreen', 'link', 'lists', 'table', 'image'],
    menubar: false,
    toolbar: `undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent | link image | fullscreen`,
    content_style: [contentCss, contentUiCss].join('\n'),
  });
};
