import Tablesort from 'tablesort';

// TODO: @Koda to fix importing of this later. This is the tablesort numbers module which we were having issues importing.
(function number() {
  function cleanNumber(i) {
    return i.replace(/[^\-?0-9.]/g, '');
  }

  function compareNumber(a, b) {
    const parseA = parseFloat(a);
    const parseB = parseFloat(b);

    const numberA = Number.isNaN(parseA) ? 0 : parseA;
    const numberB = Number.isNaN(parseB) ? 0 : parseB;

    return numberA - numberB;
  }

  Tablesort.extend(
    'number',
    (item) => item.match(/^[-+]?[£\x24Û¢´€]?\d+\s*([,.]\d{0,2})/) // Prefixed currency
      || item.match(/^[-+]?\d+\s*([,.]\d{0,2})?[£\x24Û¢´€]/) // Suffixed currency
      || item.match(/^[-+]?(\d)*-?([,.]){0,1}-?(\d)+([E,e][-+][\d]+)?%?$/), // Number
    (a, b) => compareNumber(cleanNumber(b), cleanNumber(a)),
  );
}());

// Add class table-sortable to get a sortable table
const loadTableSort = () => {
  const sortedTables = document.getElementsByClassName('table-sortable');
  for (let i = 0; i < sortedTables.length; i += 1) {
    const v = new Tablesort(sortedTables[i]); // eslint-disable-line no-unused-vars
  }
};

export default loadTableSort;
