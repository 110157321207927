import DangerIcon from '@assets/images/toast-danger-icon.svg';
import SuccessIcon from '@assets/images/toast-success-icon.svg';
import consumer from './consumer';

const icons = {
  danger: DangerIcon,
  success: SuccessIcon,
};

let alertPlaceholder = null;

const appendAlert = ({ message, type = 'success' }) => {
  const wrapper = document.createElement('div');
  wrapper.classList.add('toast', 'show', 'fade', 'position-relative');
  wrapper.setAttribute('role', 'alert');
  wrapper.setAttribute('aria-live', 'assertive');
  wrapper.setAttribute('aria-atomic', 'true');

  wrapper.innerHTML = `<div class="d-flex align-items-center pe-2">
    <div class="toast-body">
      <span class="d-inline-block me-1" style="width: 1.5em;">
        <img src="${icons[type]}" alt="" />
      </span>
      <span>${message}</span>
    </div>
    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>`;

  alertPlaceholder.append(wrapper);
};

document.addEventListener('turbo:load', () => {
  alertPlaceholder = document.getElementById('toasts');
  consumer.subscriptions.create({
    channel: 'ToastsChannel',
  }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      appendAlert(data);
    },
  });
});
