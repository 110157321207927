import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static get targets() {
    return ['form'];
  }

  reset(event) {
    event.stopPropagation();
    this.formTarget.reset();
    document.getElementById('search-results').style.display = 'none';
  }

  submit(event) {
    // Don't submit the form, listen for the input event instead
    if (event.type === 'submit') {
      event.preventDefault();
      return;
    }

    clearTimeout(this.timeout);

    const term = event.target.value;
    const url = `/api/shared/group_memberships/search?term=${term}`;

    this.timeout = setTimeout(async () => {
      if (term.length > 2) {
        document.getElementById('search-spinner').style.display = 'block';

        await get(url, { responseKind: 'turbo-stream' });

        document.getElementById('search-results').style.display = 'block';
        document.getElementById('search-spinner').style.display = 'none';
      } else {
        document.getElementById('search-results').style.display = 'none';
      }
    }, 300);
  }
}
