import { Turbo } from '@hotwired/turbo-rails';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import './channels';

Turbo.session.drive = false;
const application = Application.start();

// Configure Stimulus development experience
const context = require.context('./controllers', true, /\.\/.+.js$/);
application.load(definitionsFromContext(context));
application.debug = false;
